<template>
    <v-navigation-drawer
        v-model="drawer"
        app
        color="grey lighten-4"
        :mini-variant.sync="mini"
        permanent
        v-if="!operatorRole"
        clipped
        stateless
        :expand-on-hover="!$vuetify.breakpoint.smAndDown ? true : false"
    >
        <div class="fill-height d-flex flex-column">
            <div class="d-flex justify-space-between mt-2">
                <v-img
                    :src="companyLogo"
                    class="pa-0 ma-0"
                    max-height="60"
                    width="50%"
                    xs="56"
                    sm="56"
                    contain
                />
                <v-btn
                    v-if="!mini && $vuetify.breakpoint.smAndDown"
                    icon
                    @click.stop="closeMenu"
                >
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
            </div>
            <v-list dense nav rounded>
                <v-list-group
                    :value="salesGroup"
                    prepend-icon="mdi-account-tie"
                    no-action
                    @click="salesGroup = !salesGroup"
                >
                    <template v-slot:activator>
                        <v-list-item-title>SALES</v-list-item-title>
                    </template>
                    <v-list-item
                        v-for="item in saleItems"
                        :key="item.title"
                        color="primary"
                        :to="item.path"
                        link
                        @click="dismissDrawer('sales')"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.title.toUpperCase() }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-group
                    :value="projectGroup"
                    prepend-icon="mdi-account-hard-hat"
                    no-action
                    @click="projectGroup = !projectGroup"
                >
                    <template v-slot:activator>
                        <v-list-item-title>PROJECTS</v-list-item-title>
                    </template>
                    <v-list-item
                        v-for="item in projectsItems"
                        :key="item.title"
                        color="primary"
                        :to="item.path"
                        link
                        @click="dismissDrawer('projects')"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.title.toUpperCase() }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-group
                    :value="productionGroup"
                    prepend-icon="mdi-hammer-screwdriver"
                    no-action
                    @click="productionGroup = !productionGroup"
                >
                    <template v-slot:activator>
                        <v-list-item-title>PRODUCTION</v-list-item-title>
                    </template>
                    <v-list-item
                        v-for="item in productionItems"
                        :key="item.title"
                        color="primary"
                        :to="item.path"
                        link
                        @click="dismissDrawer('production')"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.title.toUpperCase() }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-group
                    v-if="tasks"
                    :value="tasksGroup"
                    prepend-icon="mdi-list-box"
                    no-action
                    @click="tasksGroup = !tasksGroup"
                >
                    <template v-slot:activator>
                        <v-list-item-title>TASKS</v-list-item-title>
                    </template>
                    <v-list-item
                        v-for="item in taskItems"
                        :key="item.title"
                        color="primary"
                        :to="item.path"
                        link
                        @click="dismissDrawer('tasks')"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.title.toUpperCase() }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-group
                    :value="settingsGroup"
                    prepend-icon="mdi-cog"
                    no-action
                    @click="settingsGroup = !settingsGroup"
                >
                    <template v-slot:activator>
                        <v-list-item-title>SETTINGS</v-list-item-title>
                    </template>
                    <v-list-item
                        v-for="item in settingsItems"
                        :key="item.title"
                        color="primary"
                        :to="item.path"
                        link
                        @click="dismissDrawer('settings')"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.title.toUpperCase() }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>

            <v-spacer />

            <v-divider />

            <v-list class="pa-0">
                <v-progress-linear indeterminate v-if="loading" />
                <v-list-item link @click="signOut">
                    <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            LOG OUT
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </div>
    </v-navigation-drawer>
</template>

<script>
import { auth, messaging, vapidKey } from '@/services/firebase'
import { mapActions, mapMutations, mapState } from 'vuex'
import API from '@/services/api'

export default {
    name: 'NavDrawer',
    data() {
        return {
            salesGroup: false,
            projectGroup: false,
            productionGroup: false,
            tasksGroup: true,
            settingsGroup: false,
            loading: false,
            drawer: true,
            mini: true,
            error: false,
            errorMsg: null,
            tasks: null,
            taskItems: [
                {
                    title: 'Main Board',
                    icon: 'mdi-clipboard-outline',
                    path: '/tasks',
                },
                {
                    title: 'Assign Board',
                    icon: 'mdi-bulletin-board',
                    path: '/assign',
                },
                {
                    title: 'Archive',
                    icon: 'mdi-archive-outline',
                    path: '/archive-tasks',
                },
            ],
            saleItems: [
                {
                    title: 'Leads',
                    icon: 'mdi-handshake-outline',
                    path: '/leads',
                },
                {
                    title: 'Main Board',
                    icon: 'mdi-clipboard-outline',
                    path: '/main-board',
                },
                {
                    title: 'Estimating Plan',
                    icon: 'mdi-file-check-outline',
                    path: '/estimating-plan',
                },
                {
                    title: 'Commercial Tools',
                    icon: 'mdi-image-multiple-outline',
                    path: '/tools',
                },
                {
                    title: 'Clients',
                    icon: 'mdi-office-building-outline',
                    path: '/clients',
                },
                {
                    title: 'Archive',
                    icon: 'mdi-archive-outline',
                    path: '/history',
                },
                {
                    title: 'Calculations',
                    icon: 'mdi-alarm-panel-outline',
                    path: '/calculations',
                },
                {
                    title: 'Statistics',
                    icon: 'mdi-chart-line',
                    path: '/Statistics',
                },
                {
                    title: 'chats',
                    icon: 'mdi-forum-outline',
                    path: '/chats',
                },
            ],
            projectsItems: [
                {
                    title: 'Main Board',
                    icon: 'mdi-screw-machine-flat-top',
                    path: '/tab-projects',
                },
                {
                    title: 'Forecast',
                    icon: 'mdi-finance',
                    path: '/forecast',
                },
                {
                    title: 'Purchasing',
                    icon: 'mdi-cart-outline',
                    path: '/purchasing',
                },
                {
                    title: 'Invoicing',
                    icon: 'mdi-receipt-text-outline',
                    path: '/invoicing',
                },
                {
                    title: 'Suppliers',
                    icon: 'mdi-account-box-outline',
                    path: '/suppliers',
                },
                {
                    title: 'Closing',
                    icon: 'mdi-archive-lock-open-outline',
                    path: '/closing-projects',
                },
                {
                    title: 'Archive',
                    icon: 'mdi-archive-outline',
                    path: '/archive-projects',
                },
                {
                    title: 'chats',
                    icon: 'mdi-forum-outline',
                    path: '/project-chats',
                },
            ],
            productionItems: [
                {
                    title: 'Stock Room',
                    icon: 'mdi-database-outline',
                    path: '/stock-room',
                },
                {
                    title: 'Work Orders',
                    icon: 'mdi-factory',
                    path: '/work-orders',
                },
                {
                    title: 'Packing',
                    icon: 'mdi-package-variant',
                    path: '/packing',
                },
            ],
            settingsItems: [
                {
                    title: 'Users',
                    icon: 'mdi-account-group-outline',
                    path: '/users',
                },
                {
                    title: 'Set Up',
                    icon: 'mdi-tools',
                    path: '/settings',
                },
            ],
            companyLogo: '',
            readStatistics: false,
            user: {},
            operatorRole: true,
            archiveProject: false,
        }
    },
    computed: {
        ...mapState(['settings', 'userRef', 'route']),
        taskGroup: function() {
            return (
                this.taskItems.find(
                    item => item.path == this.$router.currentRoute.name
                ) != undefined
            )
        },
        activeRoute() {
            return this.route
        },
    },
    async mounted() {
        try {
            this.filterByPermissions()
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user
            this.operatorRole = user.operatorRole
        } catch (error) {
            console.log(error)
        }
    },
    watch: {
        settings(val) {
            val.forEach(setting => {
                if (setting && setting.name == 'Company') {
                    this.companyLogo = setting.generalInfo.image.imageURL
                }
            })
        },
    },
    methods: {
        ...mapActions(['resetStateAction']),
        ...mapMutations(['setRoute', 'setErrorItems']),
        dismissDrawer(listGroup) {
            if (listGroup == 'sales') {
                this.salesGroup = true
            } else if (listGroup == 'projects') {
                this.projectGroup = true
            } else if (listGroup == 'production') {
                this.productionGroup = true
            } else if (listGroup == 'tasks') {
                this.tasksGroup = true
            } else if (listGroup == 'settings') {
                this.settingsGroup = true
            }
            this.mini = true
        },
        filterByPermissions() {
            this.archiveProject = this.userRef.permissions.find(
                x => x == 'archiveProject'
            )
            if (
                !this.archiveProject &&
                !this.userRef.permissions.includes('comexEvidence') &&
                !this.userRef.permissions.includes('portfolioEvidence') &&
                !this.userRef.permissions.includes('sapEvidence')
            ) {
                const index = this.projectsItems.findIndex(
                    item => item.path === '/closing-projects'
                )
                this.projectsItems.splice(index, 1)
            }
            this.readStatistics = this.userRef.permissions.find(
                x => x == 'readStatistics'
            )
            this.calculations = this.userRef.permissions.find(
                x => x == 'calculations'
            )
            this.tasks = this.userRef.permissions.find(x => x == 'readTasks')
            const suppliers = this.userRef.permissions.find(
                x => x == 'readSuppliers'
            )
            if (!suppliers) {
                const index = this.projectsItems.findIndex(
                    item => item.path === '/suppliers'
                )
                this.projectsItems.splice(index, 1)
            }
            if (!this.readStatistics) {
                const index = this.saleItems.findIndex(
                    item => item.path === '/Statistics'
                )
                this.saleItems.splice(index, 1)
            }
            if (!this.calculations) {
                const index = this.saleItems.findIndex(
                    item => item.path === '/calculations'
                )
                this.saleItems.splice(index, 1)
            }
        },
        closeMenu() {
            this.mini = true
        },
        signOut: async function() {
            try {
                this.loading = true
                localStorage.clear()
                //
                await auth().signOut()
                this.$router
                    .replace({ name: 'sign in' })
                    .catch(error => error && null)
                this.resetStateAction()
                this.loading = false
            } catch (error) {
                this.error = true
                this.errorMsg = error.message
            } finally {
                // unsubscribe from topic for notifications
                if (messaging) {
                    const currentToken = await messaging.getToken({ vapidKey })
                    await API.unsubscribeFromTopic(currentToken).catch(
                        error => {
                            console.log(error.message)
                        }
                    )
                }
            }
        },
    },
}
</script>

<style scoped>
.v-navigation-drawer {
    transform: translateX(0%) !important;
    width: 56px;
}
</style>
